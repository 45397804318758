<template>
  <div class="navBarCss">
    <div class="iconContentCss">
      <svg-icon icon-class="home" width="1.2rem" height="1.2rem" />
    </div>
    <div class="honeCss" @click="goHome">首页</div>
    <span>/</span>
    <div class="pageNameCss">{{ pageName }}</div>
  </div>
</template>

<script>
export default {
  props: {
    pageName: {
      type: String,
      default: ''
    }
  },
  methods: {
    goHome() {
      this.$router.push('/')
    }
  }
}
</script>

<style lang="stylus" scoped>
marginRight = 0.5rem
.navBarCss {
  width 100%
  height 3rem
  border-bottom 1px solid #666666
  .iconContentCss {
    float left
    margin 0.9rem marginRight 0 20rem
  }
  .honeCss {
    float left
    height 3rem
    line-height 3rem
    margin-right marginRight
    color #e84c3d
    font-weight bold
    cursor pointer // 鼠标光标变为手的形状
  }
  .honeCss:hover {
    text-decoration underline
  }
  span {
    color white
    float left
    height 3rem
    line-height 3rem
    margin-right marginRight
  }
  .pageNameCss {
    color white
    height 3rem
    line-height 3rem
  }
}
</style>
