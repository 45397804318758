<template>
  <div class="programmeCss">
    <Navbar :pageName="pageName" />
    <div class="imgContentCss">
      <img :src="require('@/assets/images/blogBookIcon.png')" />
    </div>
    <div class="programmeTitleCss">{{pageName}}</div>
    <div class="contentMsgCss">
      <div class="contentLeftCss">
        <img v-for="(item, index) in programmeMsg.imgList" :key="index" :src="item.url" />
        <div class="leftMsgCss">
          <div class="leftTitleCss">{{pageName}}</div>
          <div v-for="(item, index) in programmeMsg.msgList" :key="index">
            <p class="msgCss" :class="{mainMsgCss: item.main}">{{item.msg}}</p>
          </div>
        </div>
      </div>
      <div class="contentRightCss">
        <div class="rightTitleCss">方案组成</div>
        <Tab
          :allCur="3"
          :cur="programmeMsgType"
          :tabTitleArr="tabTitleArr"
          isSelectBackColor="#e84c3d"
          @change="tabChange"
        />
        <SchCompo :schCompoData="schCompoMsg" />
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from '@/views/home/components/Navbar.vue'
import Tab from '@/components/myComp/tab/Tab.vue'
import SchCompo from './components/SchCompo.vue'
import { wsczzhjkyyglxt, ylCTjycjkzdxt, jxsycjkglxt, zyssbyyglxt, skjcywglxt, trqysjycjkxt, syzjycjkglxt, gkjxycjkglxt, dtycjkglxt, cnsbznhycjkxt, bpqwlwycjkxt, gyqynhjzjcxt } from '@/assets/basicData.js'

export default {
  name: 'programme',
  components: {
    Navbar,
    Tab,
    SchCompo
  },
  data() {
    return {
      pageName: '',
      programmeMsg: {},
      tabTitleArr: [
        { title: '平台软件' },
        { title: '智能网关' },
        { title: '数据中心' }
      ],
      programmeMsgType: 0, // 方案组成类型
      schCompoMsg: {}, // 方案组成内容
      wsczzhjkyyglxt, // 污水厂站智慧集控运营管理系统
      ylCTjycjkzdxt, // 医疗CT机远程监控诊断系统
      jxsycjkglxt, // 机械手远程监控管理系统
      zyssbyyglxt, // 直饮水设备运营管理系统
      skjcywglxt, // 数控机床运维管理系统
      trqysjycjkxt, // 天然气压缩机远程监控系统
      syzjycjkglxt, // 石油钻机远程监控管理系统
      gkjxycjkglxt, // 港口机械远程监控管理系统
      dtycjkglxt, // 电梯远程监控管理系统
      cnsbznhycjkxt, // 储能设备智能化远程监控系统
      bpqwlwycjkxt, // 变频器物联网远程监控系统

      gyqynhjzjcxt // 工业企业能耗集中监测系统
    }
  },
  mounted() {
    this.pageName = this.$route.query.pageName
    this.setProgrammeMsg()
    this.setSchCompoMsg()
    window.addEventListener('setItem', () => {
      this.pageName = localStorage.getItem('pageName')
      this.setProgrammeMsg()
    })
  },
  methods: {
    setProgrammeMsg() {
      switch(this.pageName) {
        case '污水厂站智慧集控运营管理系统' : this.programmeMsg = this.$lodash.cloneDeep(this.wsczzhjkyyglxt); break
        case '医疗CT机远程监控诊断系统' : this.programmeMsg = this.$lodash.cloneDeep(this.ylCTjycjkzdxt); break
        case '机械手远程监控管理系统' : this.programmeMsg = this.$lodash.cloneDeep(this.jxsycjkglxt); break
        case '直饮水设备运营管理系统' : this.programmeMsg = this.$lodash.cloneDeep(this.zyssbyyglxt); break
        case '数控机床运维管理系统' : this.programmeMsg = this.$lodash.cloneDeep(this.skjcywglxt); break
        case '天然气压缩机远程监控系统' : this.programmeMsg = this.$lodash.cloneDeep(this.trqysjycjkxt); break
        case '石油钻机远程监控管理系统' : this.programmeMsg = this.$lodash.cloneDeep(this.syzjycjkglxt); break
        case '港口机械远程监控管理系统' : this.programmeMsg = this.$lodash.cloneDeep(this.gkjxycjkglxt); break
        case '电梯远程监控管理系统' : this.programmeMsg = this.$lodash.cloneDeep(this.dtycjkglxt); break
        case '储能设备智能化远程监控系统' : this.programmeMsg = this.$lodash.cloneDeep(this.cnsbznhycjkxt); break
        case '变频器物联网远程监控系统' : this.programmeMsg = this.$lodash.cloneDeep(this.bpqwlwycjkxt); break

        case '工业企业能耗集中监测系统' : this.programmeMsg = this.$lodash.cloneDeep(this.gyqynhjzjcxt); break
      }
    },
    setSchCompoMsg() {
      this.programmeMsg.schCompoMsgList.forEach(item => {
        if (item.title === this.tabTitleArr[this.programmeMsgType].title) {
          this.schCompoMsg = item
        }
      })
    },
    tabChange(e) {
      this.programmeMsgType = e
      this.setSchCompoMsg()
    }
  }
}
</script>

<style lang="stylus" scoped>
leftWidth = 44rem

.programmeCss {
  width 99.8rem
  background-color #333439
  font-size 0.8rem
  .imgContentCss {
    text-align center
  }
  .programmeTitleCss {
    color white
    text-align center
    font-size 2rem
    height 4rem
    line-height 4rem
  }
  .contentMsgCss {
    padding 1rem 14rem
    color white
    .contentLeftCss {
      float left
      width leftWidth
      border 1px solid #5c5959
      img {
        width leftWidth
      }
      .leftMsgCss {
        padding 0.3rem
        .leftTitleCss {
          color white
          font-size 1.2rem
          font-weight bolder
        }
        .msgCss {
          text-indent 1.6rem
        }
        .mainMsgCss {
          border-left 0.3rem solid #db4420
          padding-left 1rem
        }
      }
    }
    .contentRightCss {
      float left
      margin-left 2rem
      width 24rem
      .rightTitleCss {
        font-size 1.2rem
        height 2rem
        line-height 2rem
      }
    }
  }
  .contentMsgCss:after { // 使 contentMsgCss 适应内部高度
    clear both
    content ''
    width 0
    height 0
    display block
    visibility hidden
  }
}
</style>
