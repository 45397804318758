<template>
  <div class="schCompoCss">
    <div class="imgContentCss">
      <img v-for="(item, index) in schCompoData.imgList" :key="index" :src="item.url">
    </div>
    <div class="titleCss">{{schCompoData.title}}</div>
    <div class="msgCss" v-for="(msg, index) in schCompoData.msgArr" :key="index">{{msg}}</div>
  </div>
</template>

<script>
export default {
  props: {
    schCompoData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.schCompoCss {
  width 92%
  padding 1rem 4% 2rem 4%
  background-color #2f383d
  img {
    width 100%
  }
  .titleCss {
    font-size 1.2rem
    font-weight bolder
  }
  .msgCss {
    margin-top 1rem
    text-indent 2rem
  }
}
</style>
