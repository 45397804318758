<template>
  <div>
    <ul :style="{height: lineHeight,'border-bottom': borderBottom}" v-if="tabType === 'transverse'">
      <li
        v-for="(item, index) in tabTitleArr"
        :key="index"
        :style="{width: liWidth,'background': background(index),color: color(index)}"
        @click="curVal=index"
      >
        <div class="msgContenCss">
          <div class="svgIconContentCss" v-if="item.iconName">
            <svg-icon :iconClass="item.iconName" :width="iconSize" :height="iconSize" :color="iconColor" />
          </div>
            {{item.title}}
        </div>
      </li>
    </ul>

    <div v-if="tabType === 'vertical'">
      <div class="verticalCss">
        <div
          class="tabTitleItemCss"
          v-for="(item, index) in tabTitleArr"
          :key="index"
          :style="{height: lineHeight,'background': background(index),color: color(index)}"
          @click="curVal=index">
          <div class="msgContenCss">
            <div class="svgIconContentCss" v-if="item.iconName">
              <svg-icon :iconClass="item.iconName" :width="iconSize" :height="iconSize" :color="iconColor" />
            </div>
            {{item.title}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabType: { // transverse 横向排列, vertical 纵向排列
      type: String,
      default: 'transverse'
    },
    lineHeight: { // 表格行高
      type: String,
      default: '2rem'
    },
    tabTitleArr: {
      type: Array,
      default: () => {
        return [
          { title: '平台软件' },
          { title: '智能网关' },
          { title: '数据中心' }
        ]
      }
    },
    cur: {
      type: Number,
      default: 0
    },
    iconSize: {
      type: String,
      default: '0.7rem'
    },
    isSelectBackColor: { // 被选中的项背景色
      type: String,
      default: '#09f'
    },
    notIsSelectBackColor: { // 未被选中的项背景色
      type: String,
      default: ''
    },
    isSelectfontColor: { // 被选中的项文字颜色
      type: String,
      default: 'white'
    },
    notIsSelectfontColor: { // 未被选中的项文字颜色
      type: String,
      default: '#666666'
    },
    isSelectIconColor: { // 被选中时的图标颜色
      type: String,
      default: 'white'
    },
    noteIsSelectIconColor: { // 未被选中时的图标颜色
      type: String,
      default: '#666666'
    },
    allCur: { // 显示标签的最大个数
      type: Number,
      default: 6
    }
  },
  data() {
    return {
      curVal: this.cur
    }
  },
  watch: {
    curVal(val) {
      this.$emit('change', val)
    }
  },
  computed: {
    borderBottom() {
      return `1px solid ${this.isSelectBackColor}`
    },
    liWidth() {
      const maxCur = this.allCur > this.tabTitleArr.length ? this.allCur : this.tabTitleArr.length
      return (1 / maxCur ) * 100 + '%'
    },
    background() {
      return (val) => {
        return this.cur === val ? this.isSelectBackColor : this.notIsSelectBackColor
      }
    },
    color() {
      return (val) => {
        return this.cur === val ? this.isSelectfontColor : this.notIsSelectfontColor
      }
    },
    iconColor() {
      return (val) => {
        return this.cur === val ? this.isSelectIconColor : this.noteIsSelectIconColor
      }
    }
  }
}
</script>

 <style lang="stylus" scoped>
ul {
  width 100%
  padding-inline-start 0
  font-size 0.8rem
  li {
    height 100%
    display flex // display flex 配合 margin auto 实现居中效果
    float left
    cursor pointer // 鼠标指针变手
    list-style none
    .msgContenCss {
      margin auto
      .svgIconContentCss {
        float left
        margin-right 0.2rem
      }
    }
  }
}

.verticalCss {
  font-size 0.8rem
  width 100%
  .tabTitleItemCss {
    width 100%
    display flex
    cursor pointer // 鼠标指针变手
    .msgContenCss {
      margin auto
      .svgIconContentCss {
        float left
        margin-right 1rem
      }
    }
  }
}
</style>